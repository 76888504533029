import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Blog from "../components/Blog";
import Hero from "../components/Hero";
import Seo from "../components/Seo";

class IndexPage extends React.Component {
  separator = React.createRef();

  scrollToContent = e => {
    this.separator.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  render() {
    const {
      data: {
        posts: { edges: posts = [] },
        bgDesktopWebp: { fluid: desktopWebp },
        bgDesktopPng: { fluid: desktopPng },
        site: {
          siteMetadata: { facebook }
        }
      }
    } = this.props;

    const backgrounds = {
      desktopWebp,
      desktopPng
    };

    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <Hero scrollToContent={this.scrollToContent} backgrounds={backgrounds} theme={theme} />
          )}
        </ThemeContext.Consumer>

        <hr ref={this.separator} />

        {/* <ThemeContext.Consumer>
          {theme => (
            <IndexSell
              scrollToContent={this.scrollToContent}
              backgrounds={backgrounds}
              theme={theme}
            />
          )}
        </ThemeContext.Consumer> */}

        <hr ref={this.separator} />

        <ThemeContext.Consumer>
          {theme => <Blog posts={posts} theme={theme} />}
        </ThemeContext.Consumer>

        <Seo facebook={facebook} />

        <style jsx>{`
          hr {
            margin: 0;
            border: 0;
          }
        `}</style>
      </React.Fragment>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query IndexQuery {
    posts: allPrismicGenericPost(sort: { fields: first_publication_date, order: DESC }, limit: 10) {
      edges {
        node {
          id
          first_publication_date
          last_publication_date
          slugs
          uid
          tags
          data {
            author_link {
              id
              slug
              document {
                data {
                  author_name {
                    html
                    text
                  }
                }
              }
            }
            title {
              html
              text
            }
            content {
              html
              text
              raw {
                type
                text
              }
            }
            thumbnail_image {
              url
              alt
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktopWebp: imageSharp(fluid: { originalName: { regex: "/hero-background.webp/" } }) {
      fluid(quality: 90, maxWidth: 1200, cropFocus: CENTER) {
        ...GatsbyImageSharpFluid
      }
    }
    bgDesktopPng: imageSharp(fluid: { originalName: { regex: "/hero-background.png/" } }) {
      fluid(quality: 90, maxWidth: 1200, cropFocus: CENTER) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
